import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateDimensionData } from 'actions/dimensions-data';
import { displayNotification } from 'actions/notification';
import useApi from 'hooks/api';

/* eslint-disable react/prop-types, jsx-a11y/label-has-associated-control */
export default ({ hidden, userId, onClose }) => {
  // Local state
  const [formData, setFormData] = useState({});

  // Redux
  const dispatch = useDispatch();
  const fetchData = useApi();

  useEffect(() => {
    fetchData(`profile/${userId}`)
      .then(setFormData)
      .catch(({ message = 'Error while requesting API' }) => dispatch(displayNotification({
        message,
        type: 'error',
      })));
  }, [userId]);

  // Handle on submit
  const handleSubmit = useCallback(event => {
    event.preventDefault();

    // Post request
    fetchData(`profile/${userId}`, { method: 'POST', data: formData })
      .then(() => {
        dispatch(updateDimensionData({ ...formData, id: Number(userId) }));
        dispatch(displayNotification({ message: 'Succès', type: 'success' }));
        onClose();
      })
      .catch(({ message = 'Error while requesting API' }) => dispatch(displayNotification({
        message,
        type: 'error',
      })));
  }, [dispatch, formData, userId]);

  // Hidden ?
  if (hidden) {
    return null;
  }

  return (
    <form
      className="fade-in w-full fixed z-10 w-full h-full inset-0 flex justify-center items-center"
      style={{ backgroundColor: '#00000020' }}
      onSubmit={handleSubmit}
    >
      <div
        className="p-8 rounded-lg"
        style={{
          backgroundColor: '#323c48',
          boxShadow: '0px 0px 27px rgba(0, 0, 0, 0.5)',
        }}
      >
        <div className="text-gray-200 text-xl mb-8 pb-2 border-b border-gray-200">
          Modifier le consultant
        </div>
        <div className="block text-gray-200 font-bold mb-1 mb-0 pr-4">
          Nom
        </div>
        <input
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
          type="text"
          value={formData.name}
          onChange={event => setFormData({ ...formData, name: event.target.value })}
        />
        <p>&nbsp;</p>
        <div className="block text-gray-200 font-bold mb-1 mb-0 pr-4">
          Couleur
        </div>
        <input
          type="color"
          value={formData.color}
          onChange={event => setFormData({ ...formData, color: event.target.value })}
        />
        <div className="flex justify-end items-center mt-8">
          <button
            className="shadow bg-gray-600 hover:bg-gray-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={() => onClose()}
          >
            Annuler
          </button>
          &nbsp;&nbsp;
          <button
            className="shadow bg-blue-700 hover:bg-blue-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="submit"
          >
            Soumettre
          </button>
        </div>
      </div>
    </form>
  );
};
