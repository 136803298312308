import React, { useState, useEffect, useRef } from 'react';
import LineChart from './line-chart';
import BarChart from './bar-chart';

/* eslint-disable react/prop-types */
export default ({
  label,
  data,
  dataKey,
  dimensions,
  height = 320,
  tick,
}) => {
  // Local state
  const [isLineChart, setLineChart] = useState(true);
  const [isCumulative, setCumulative] = useState(!dataKey.endsWith('_rate'));
  const [width, setWidth] = useState(0);
  const node = useRef(null);

  // Init chart width
  useEffect(() => {
    setWidth(node.current.offsetWidth);
  }, [node]);

  // Map values
  let values = data;

  // Cumulative
  if (isCumulative) {
    values = values.reduce((acc, item, i) => {
      if (!i) {
        return acc.concat(item);
      }

      return acc.concat(Object.keys(item).reduce((items, key) => ({
        ...items,
        [key]: item[key] + (acc[i - 1] || { [key]: 0 })[key],
        tick: item.tick,
      }), {}));
    }, []);
  }

  // Pick Component according chart type
  const Chart = isLineChart ? LineChart : BarChart;

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="w-16" />
        <p className="font-medium text-lg">{label}</p>
        <p className="flex items-center justify-end w-16 -mr-1">
          <button
            type="button"
            onClick={() => setLineChart(!isLineChart)}
            title="Line / Bar"
            className={isLineChart ? 'p-1 text-lg text-blue-500' : 'p-1 text-lg'}
          >
            ∿
          </button>
          {!dataKey.endsWith('_rate') && (
            <button
              type="button"
              onClick={() => setCumulative(!isCumulative)}
              title="Cumulative"
              className={isCumulative ? 'p-1 ml-1 text-lg text-blue-500' : 'p-1 text-lg ml-1'}
            >
              Σ
            </button>
          )}
        </p>
      </div>
      <div style={{ minHeight: `${height}px` }} className="mt-4" ref={node}>
        {width && (
          <Chart
            width={width}
            height={height}
            data={values}
            dataKey={dataKey}
            dimensions={dimensions}
            tick={tick}
          />
        )}
      </div>
    </>
  );
};
