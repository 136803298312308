import React, { useState, useEffect, useRef } from 'react';
import LineChart from './line-chart';
import BarChart from './bar-chart';

/* eslint-disable react/prop-types */
export default ({
  data,
  dataKey,
  tick,
  height = 75,
}) => {
  // Local state
  const [isLineChart, setLineChart] = useState(true);
  const [isCumulative, setCumulative] = useState(!dataKey.endsWith('_rate'));
  const [isFullScreen, setFullScreen] = useState(false);
  const [width, setWidth] = useState(0);
  const node = useRef(null);

  // Init chart width
  useEffect(() => {
    setWidth(node.current.offsetWidth);
  }, [node]);

  // Map values
  let values = data.map(item => ({
    tick: item.timestamp,
    value: item[dataKey],
  }));

  // Cumulative
  if (isCumulative) {
    values = values.reduce((acc, item, i) => acc.concat({
      ...item,
      value: item.value + (acc[i - 1] || { value: 0 }).value,
    }), []);
  }

  // Pick Component according chart type
  const Chart = isLineChart ? LineChart : BarChart;

  return (
    <>
      <div style={{ minHeight: `${height}px` }} className="mt-2" ref={node}>
        {width && <Chart width={width} height={height} data={values} dataKey={dataKey} tick={tick} />}
      </div>
      <p className="flex items-center justify-end -mr-1">
        <button
          type="button"
          onClick={() => setLineChart(!isLineChart)}
          title="Line / Bar"
          className={isLineChart ? 'p-1 text-blue-500' : 'p-1'}
        >
          ∿
        </button>
        {!dataKey.endsWith('_rate') && (
          <button
            type="button"
            onClick={() => setCumulative(!isCumulative)}
            title="Cumulative"
            className={isCumulative ? 'p-1 ml-1 text-blue-500' : 'p-1 ml-1'}
          >
            Σ
          </button>
        )}
        <button
          type="button"
          onClick={() => setFullScreen(true)}
          title="Agrandir"
          className={isFullScreen ? 'p-1 ml-1 text-blue-500' : 'p-1 ml-1'}
        >
          ⛶
        </button>
      </p>
      {isFullScreen && (
        <div
          className="fade-in fixed w-full h-full inset-0 z-10 flex justify-center items-center"
          style={{ backgroundColor: '#00000030' }}
        >
          <div
            className="relative px-8 pb-8 rounded-lg"
            style={{
              backgroundColor: '#323c48',
              boxShadow: '0px 0px 27px rgba(0, 0, 0, 0.5)',
            }}
          >
            <p className="flex items-center justify-end py-4 -mr-1">
              <button
                type="button"
                onClick={() => setLineChart(!isLineChart)}
                title="Line / Bar"
                className={isLineChart ? 'text-2xl p-1 text-blue-500' : 'text-2xl p-1'}
              >
                ∿
              </button>
              {!dataKey.endsWith('_rate') && (
                <button
                  type="button"
                  onClick={() => setCumulative(!isCumulative)}
                  title="Cumulative"
                  className={isCumulative ? 'text-2xl p-1 ml-1 text-blue-500' : 'text-2xl p-1 ml-1'}
                >
                  Σ
                </button>
              )}
              <button
                type="button"
                className="text-2xl text-gray-200 p-1 ml-1"
                onClick={() => setFullScreen(false)}
              >
                ⨯
              </button>
            </p>
            <Chart
              width={window.innerWidth * 0.8}
              height={window.innerHeight * 0.6}
              data={values}
              dataKey={dataKey}
              tick={tick}
              grid
            />
          </div>
        </div>
      )}
    </>
  );
};
