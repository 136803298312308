export default function (state = null, { type, payload }) {
  switch (type) {
    // Store
    case 'credentials:store':
      if (payload) {
        localStorage.setItem('credentials', `${payload.username}:${payload.password}`);
        return `${payload.username}:${payload.password}`;
      }

      localStorage.removeItem('credentials');
      return payload;

    default:
      return state;
  }
}
