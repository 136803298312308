import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeSettings } from 'actions/settings';
import { displayNotification } from 'actions/notification';
import useApi from 'hooks/api';

/* eslint-disable react/prop-types, jsx-a11y/label-has-associated-control */
export default ({ hidden, onClose }) => {
  // Current
  const [formData, setFormData] = useState(null);

  // Redux
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings);
  const currentreport = useSelector(state => state.currentreport);
  const fetchData = useApi();

  // Handle on submit
  const handleSubmit = useCallback(event => {
    event.preventDefault();

    // Check params
    if (!formData) {
      dispatch(displayNotification({ message: 'Selectionner une valeur parmis la liste', type: 'error' }));

    // ... Post request
    } else {
      fetchData('settings', {
        method: 'DELETE',
        data: { name: formData },
      })
        .then(() => {
          onClose();
          setFormData(null);
          dispatch(removeSettings(formData));
          dispatch(displayNotification({ message: 'Succès', type: 'success' }));
        })
        .catch(({ message = 'Error while requesting API' }) => dispatch(displayNotification({
          message,
          type: 'error',
        })));
    }
  }, [dispatch, formData]);

  if (hidden) {
    return null;
  }

  return (
    <form
      className="fade-in w-full fixed w-full h-full inset-0 flex justify-center items-center"
      style={{ backgroundColor: '#00000020' }}
      onSubmit={handleSubmit}
    >
      <div
        className="p-8 rounded-lg w-1/2"
        style={{
          backgroundColor: '#323c48',
          boxShadow: '0px 0px 27px rgba(0, 0, 0, 0.5)',
        }}
      >
        <div className="text-gray-200 text-xl mb-8 pb-2 border-b border-gray-200">
          Supprimer un rapport
        </div>
        <div className="flex items-center mb-6 px-8">
          <div className="w-1/5">
            <label className="block text-gray-200 font-bold text-right mb-1 mb-0 pr-4">
              Nom du rapport
            </label>
          </div>
          <div className="w-4/5">
            <select
              className="form-control w-full focus:outline-none focus:shadow-outline rounded py-1 px-2 block appearance-none leading-normal"
              onChange={event => setFormData(event.target.options[event.target.selectedIndex].value)}
            >
              <option value="" />
              {Object.keys(settings)
                  .filter(item => item !== currentreport)
                  .map(item => <option value={item} selected={item === formData}>{item}</option>)}
            </select>
          </div>
        </div>
        <div className="flex items-center px-8">
          <div className="w-1/5" />
          <div className="w-4/5">
            <button
              className="shadow bg-gray-600 hover:bg-gray-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => {
                setFormData(null);
                onClose();
              }}
            >
              Annuler
            </button>
            &nbsp;&nbsp;
            <button
              className="shadow bg-red-700 hover:bg-red-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
