import { put, select } from 'redux-saga/effects';
import { API_URL } from 'helpers/constants';
import { setIsFetching } from 'actions/is-fetching';
import { displayNotification } from 'actions/notification';
import { storeCredentials } from 'actions/credentials';

export default function* fetchData(url, params = { method: 'GET' }) {
  try {
    const credentials = yield select(state => state.credentials);

    // Show loading indicator
    yield put(setIsFetching(true));

    // Fetch data
    const response = yield fetch(`${API_URL}/${url}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(credentials)}`,
      },
      method: params.method,
      body: params.data ? JSON.stringify(params.data) : null,
    });

    // Ok ?
    if (response.ok) {
      const data = yield response.json();
      return data;
    }

    // Unauthorized ?
    if (response.status === 401) {
      yield put(storeCredentials(null));
      yield put(displayNotification({ message: 'Unauthorized to use API', type: 'error' }));

    // Display error code
    } else {
      yield put(displayNotification({ message: `Unexpected code from API: ${response.status}`, type: 'error' }));
    }

  // ... error
  } catch (error) {
    yield put(displayNotification({ message: error.message || 'Error while requesting API', type: 'error' }));

  // Hide loading indicator
  } finally {
    yield put(setIsFetching(false));
  }

  return null;
}
