import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatValue, formatTick } from 'helpers';
import { FACT_LABELS } from 'helpers/constants';

/* eslint-disable react/prop-types */
export default ({
  data,
  dataKey,
  tick,
  width,
  height,
  grid = false,
}) => (
  <BarChart data={data} barSize={16} width={width} height={height}>
    {grid && <CartesianGrid strokeDasharray="1 5" stroke="#98a4b9A0" />}
    <YAxis
      hide={!grid}
      dataKey="value"
      domain={[0, 'dataMax']}
      type="number"
      scale="linear"
      tickFormatter={value => formatValue(value, dataKey)}
    />
    <XAxis
      hide={!grid}
      dataKey="tick"
      domain={['dataMin', 'dataMax']}
      type="number"
      scale="time"
      tickFormatter={value => formatTick(value, tick)}
      padding={grid ? { left: 10, right: 10 } : undefined}
    />
    <Bar dataKey="value" fill={grid ? '#38b2ac' : '#98a4b9'} />
    <Tooltip
      isAnimationActive={false}
      labelFormatter={value => formatTick(value, tick)}
      formatter={value => [formatValue(value, dataKey), FACT_LABELS[dataKey]]}
    />
  </BarChart>
);
