import { put, select, takeEvery } from 'redux-saga/effects';
import { storeDimensionsData } from 'actions/dimensions-data';

function* doSortDimensionData() {
  // Get redux
  const data = yield select(state => state.dimensionsdata);
  const order = yield select(state => state.currentorder);

  yield put(storeDimensionsData({
    payload: data.payload,
    dimensions: order
      ? data.dimensions.sort((a, b) => data.payload[b.id][order] - data.payload[a.id][order])
      : data.dimensions.sort((a, b) => a.name.localeCompare(b.name)),
  }));
}

/* eslint-disable  import/prefer-default-export */
export function* watchCurrentOrder() {
  yield takeEvery('current-order:store', doSortDimensionData);
}
/* eslint-enable  import/prefer-default-export */
