export default function (state = { dimensions: [], payload: {} }, { type, payload }) {
  switch (type) {
    // Store
    case 'dimensions-data:store':
      return payload;

    // Update item
    case 'dimensions-data:update':
      return {
        ...state,
        dimensions: state.dimensions.map(item => {
          if (item.id === payload.id) {
            return { ...item, ...payload };
          }

          return item;
        }),
      };

    default:
      return state;
  }
}
