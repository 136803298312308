import { all } from 'redux-saga/effects';
import { watchFetchDimensions } from './dimensions';
import { watchFetchFacts } from './facts';
import { watchCurrentOrder } from './current-order';
import { watchFetchSettings, watchCreateSettings, watchDeleteSettings } from './settings';

export default function* rootSaga() {
  yield all([
    watchFetchDimensions(),
    watchFetchFacts(),
    watchCurrentOrder(),
    watchFetchSettings(),
    watchCreateSettings(),
    watchDeleteSettings(),
  ]);
}
