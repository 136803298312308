import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatValue, formatTick } from 'helpers';

/* eslint-disable react/prop-types */
export default ({
  data,
  dataKey,
  tick,
  width,
  height,
  dimensions,
}) => (
  <BarChart data={data} barSize={16} width={width} height={height}>
    <CartesianGrid strokeDasharray="1 5" stroke="#98a4b9A0" />
    <YAxis
      dataKey="value"
      domain={[0, 'dataMax']}
      type="number"
      scale="linear"
      tickFormatter={value => formatValue(value, dataKey)}
    />
    <XAxis
      dataKey="tick"
      domain={['dataMin', 'dataMax']}
      type="number"
      scale="time"
      tickFormatter={value => formatTick(value, tick)}
      padding={{ left: 50, right: 50 }}
    />
    {Object.keys(dimensions).map(key => (
      <Bar key={key} dataKey={key} fill={dimensions[key].color} />
    ))}
    <Tooltip
      labelFormatter={value => formatTick(value, tick)}
      formatter={(value, name) => [formatValue(value, dataKey), dimensions[name].name]}
    />
  </BarChart>
);
