import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from '@reach/router';
import { storeCurrentDimension } from 'actions/current-dimension';
import { storeCurrentOrder } from 'actions/current-order';
import useCurrentIndicators from 'hooks/current-indicators';

// Local components
import DimensionsDropdown from './components/dimensions-dropdown';
import DimensionLabels from './components/dimension-labels';
import DimensionBarChart from './components/dimension-bar-chart';
import FactChart from './components/fact-chart';

export default () => {
  // Local state
  const [checked, setChecked] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const dimension = useSelector(state => state.currentdimension);
  const order = useSelector(state => state.currentorder);
  const data = useSelector(state => state.dimensionsdata, shallowEqual);
  const facts = useSelector(state => state.factsdata, shallowEqual);
  const indicators = useCurrentIndicators();

  return (
    <div className="fade-in">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-1 self-end text-right">
          <DimensionsDropdown
            label={dimension.label}
            onPressItem={item => dispatch(storeCurrentDimension(item))}
          />
        </div>
        <div className="col-span-11">
          <div className="grid grid-cols-5 gap-4">
            {indicators.map(dataKey => (
              <FactChart
                key={dataKey}
                dataKey={dataKey}
                data={facts.data}
                total={facts[dataKey]}
                tick={facts.tick}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 my-4">
        <div className="col-span-1 flex flex-col justify-around text-right py-4">
          <DimensionLabels
            data={data.dimensions}
            checked={checked}
            onCheck={item => {
              if (checked.length < 5 && !checked.includes(item)) {
                setChecked(checked.concat(item));
              }
            }}
            onUncheck={item => setChecked(checked.filter(elem => elem !== item))}
          />
        </div>
        <div className="col-span-11">
          <div className="grid grid-cols-5 gap-4">
            {indicators.map(dataKey => (
              <DimensionBarChart
                key={dataKey}
                data={data}
                dataKey={dataKey}
                active={order === dataKey}
                onPress={() => dispatch(storeCurrentOrder((order !== dataKey ? dataKey : null)))}
                average={facts[dataKey] / (data.dimensions.length - (data.dimensions.findIndex(item => item.id === -1) >= 0 ? 1 : 0))}
              />
            ))}
          </div>
        </div>
      </div>
      {checked.length > 1 && (
        <div className="fade-in grid grid-cols-12 gap-4 my-4">
          <div className="col-span-1 flex flex-col justify-around text-right py-4">
            <Link
              to={`/compare/${checked.join(',')}`}
              className="border border-gray-600 rounded-full px-4 py-1 text-center text-xs truncate w-full"
            >
              {`Comparer (${checked.length})`}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
