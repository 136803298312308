import { shallowEqual, useSelector } from 'react-redux';

export default ids => {
  const { dimensions, payload: data } = useSelector(state => {
    const id = ids.split(',').map(item => parseInt(item, 10) || item);
    return {
      dimensions: state.dimensionsdata.dimensions.filter(item => id.includes(item.id)),
      payload: id.reduce((acc, item) => ({
        ...acc,
        [item]: state.dimensionsdata.payload[item],
      }), {}),
    };
  }, shallowEqual);

  return { dimensions, data };
};
