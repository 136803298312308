import React from 'react';
import { formatValue } from 'helpers';
import { FACT_LABELS } from 'helpers/constants';
import PieRatio from 'components/pie-ratio';

/* eslint-disable react/prop-types */
export default ({
  value,
  total,
  position,
  count,
  dataKey,
}) => (
  <div className="card px-6 py-4 rounded-lg">
    <div className="flex justify-between items-center text-xl">
      {FACT_LABELS[dataKey]}
      <div className="text-base">
        {position || count}
        <sup>{position && position > 1 ? 'ème' : 'er'}</sup>
        &nbsp;/&nbsp;
        {count}
      </div>
    </div>
    <div className="flex justify-between items-center text-4xl mt-4">
      <div>
        {formatValue(value, dataKey)}
        <span className="text-base">{` / ${formatValue(total, dataKey)}`}</span>
      </div>
      <div className="flex items-center">
        {!dataKey.endsWith('rate') && (
          <>
            <PieRatio height={48} rate={value / total} />
            <span className="text-sm ml-2">{`${((value * 100) / total).toFixed(1)} %`}</span>
          </>
        )}
      </div>
    </div>
  </div>
);
