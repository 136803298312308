import { useDispatch, useSelector } from 'react-redux';
import { displayNotification } from 'actions/notification';

export default id => {
  // Redux
  const dispatch = useDispatch();
  const data = useSelector(state => state.dimensionsdata);

  // Retrieve data
  const key = Object.keys(data.payload).find(item => item === id);

  if (key) {
    return {
      ...data.dimensions.find(item => item.id.toString() === id),
      data: data.payload[key],
    };
  }

  // ... not found
  dispatch(displayNotification({ message: 'Dimension not found !', type: 'error' }));
  return { data: [] };
};
