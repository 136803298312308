import { DIMENSIONS } from 'helpers/constants';

export default function (state = DIMENSIONS[0], { type, payload }) {
  switch (type) {
    // Store
    case 'current-dimension:store':
      return payload;

    default:
      return state;
  }
}
