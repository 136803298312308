import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatValue, formatTick } from 'helpers';

/* eslint-disable react/prop-types */
export default ({
  data,
  dataKey,
  tick,
  width,
  height,
  dimensions,
}) => (
  <LineChart data={data} width={width} height={height}>
    <CartesianGrid strokeDasharray="1 5" stroke="#98a4b9A0" />
    <YAxis
      dataKey="value"
      type="number"
      domain={[0, 'dataMax']}
      scale="linear"
      tickFormatter={value => formatValue(value, dataKey)}
    />
    <XAxis
      dataKey="tick"
      type="number"
      domain={['dataMin', 'dataMax']}
      scale="time"
      tickFormatter={value => formatTick(value, tick)}
      tickSize={4}
    />
    {Object.keys(dimensions).map(key => (
      <Line
        key={key}
        type="monotone"
        dataKey={key}
        fill={dimensions[key].color}
        stroke={dimensions[key].color}
        strokeWidth={2}
        dot={{ fill: dimensions[key].color, strokeWidth: 0, r: 3 }}
        activeDot={{ fill: dimensions[key].color, strokeWidth: 0, r: 7 }}
      />
    ))}
    <Tooltip
      labelFormatter={value => formatTick(value, tick)}
      formatter={(value, name) => [formatValue(value, dataKey), dimensions[name].name]}
    />
  </LineChart>
);
