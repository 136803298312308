import React from 'react';
import Chart from 'components/chart';
import { formatValue } from 'helpers';
import { FACT_LABELS } from 'helpers/constants';

/* eslint-disable react/prop-types */
export default ({
  data,
  dataKey,
  total,
  tick,
}) => (
  <div className="card text-center px-4 pt-4 rounded-lg">
    <div className="flex justify-between items-center">
      <p className="font-medium text-lg whitespace-no-wrap">{FACT_LABELS[dataKey]}</p>
      <p className="text-xl text-gray-400" title={`${(total || 0).toLocaleString()} €`}>
        {formatValue(total, dataKey)}
      </p>
    </div>
    <Chart data={data} dataKey={dataKey} tick={tick} />
  </div>
);
