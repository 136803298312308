import React from 'react';
import classNames from 'classnames';
import { Link } from '@reach/router';

/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
export default function DropdownItem({ to, className, ...rest }) {
  // Link to target
  if (to) {
    return (
      <li className="dropdown-item">
        <Link to={to} className={classNames('dropdown-item-link', className)} {...rest} />
      </li>
    );
  }

  // If not link item, props should contains "onClick" ...
  return (
    <li className="dropdown-item">
      <button type="button" role="menuitem" className={classNames('dropdown-item-btn', className)} {...rest} />
    </li>
  );
}
