import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { timeDay, timeMonth, timeYear } from 'd3-time';
import { Link } from '@reach/router';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { storeDateRange } from 'actions/date-range';
import { storeCurrentReport } from 'actions/current-report';
import { formatDate } from 'helpers';
import { API_URL } from 'helpers/constants';
import FetchingIndicator from 'components/fetching-indicator';
import Dropdown from 'components/dropdown';
import DropdownItem from 'components/dropdown/item';
import DropdownSeparator from 'components/dropdown/separator';
import CreateSettings from './misc/create-settings';
import DeleteSettings from './misc/delete-settings';

/* eslint-disable react/prop-types, camelcase */
export default ({ children, location }) => {
  // Local state
  const [create_form_hidden, setCreateFormHidden] = useState(true);
  const [delete_form_hidden, setDeleteFormHidden] = useState(true);
  const [focused, setFocused] = useState(null);

  // Redux
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings);
  const range = useSelector(state => state.daterange);
  const currentreport = useSelector(state => state.currentreport);
  const dimension = useSelector(state => state.currentdimension);
  const credentials = useSelector(state => state.credentials);

  /// Scroll restoration
  useEffect(() => window.scrollTo(0, 0), [location.pathname]);

  // Set period according label selected
  const handePressPeriod = useCallback(period => {
    const now = new Date();

    // Current month
    if (period === 'current_month') {
      dispatch(storeDateRange({
        from: formatDate(timeMonth.floor(now)),
        to: formatDate(now),
      }));

    // Current year
    } else if (period === 'current_year') {
      dispatch(storeDateRange({
        from: formatDate(timeYear.floor(now)),
        to: formatDate(now),
      }));

    // Last month
    } else if (period === 'last_month') {
      const date = timeMonth.offset(now, -1);

      dispatch(storeDateRange({
        from: formatDate(timeMonth.floor(date)),
        to: formatDate(timeDay.offset(timeMonth.ceil(date), -1)),
      }));

    // Last year
    } else if (period === 'last_year') {
      const date = timeYear.offset(now, -1);

      dispatch(storeDateRange({
        from: formatDate(timeYear.floor(date)),
        to: formatDate(timeDay.offset(timeYear.ceil(date), -1)),
      }));

    // Last 3 months
    } else if (period === 'last_3_months') {
      dispatch(storeDateRange({
        from: formatDate(timeMonth.floor(timeMonth.offset(now, -3))),
        to: formatDate(timeDay.offset(timeMonth.floor(now), -1)),
      }));

    // Last 6 months
    } else if (period === 'last_6_months') {
      dispatch(storeDateRange({
        from: formatDate(timeMonth.floor(timeMonth.offset(now, -6))),
        to: formatDate(timeDay.offset(timeMonth.floor(now), -1)),
      }));
    }
  }, [dispatch]);

  // Handle export data
  const handleExport = useCallback(() => {
    fetch(`${API_URL}/extract/${dimension.key}?from=${range.from}&to=${range.to}`, {
      headers: {
        Authorization: `Basic ${btoa(credentials)}`,
      },
    })
      .then(response => response.blob())
      .then(URL.createObjectURL)
      .then(url => {
        // Create link
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Export_orders.xls';
        link.click();

        setTimeout(() => window.URL.revokeObjectURL(url), 250);
      });
  }, [credentials, dimension, range]);

  return (
    <>
      <FetchingIndicator />
      <div className="container mx-auto pt-4 pb-12">
        <div className="flex justify-between items-center">
          <Link to="/">
            <img src="/logo.png" alt="Voyage-Langue" width="256" />
          </Link>
          <form className="flex">
            <div className="mr-16">
              <div>
                <span className="font-semibold">Rapport :</span>
              </div>
              <div className="flex items-center mt-1">
                <Dropdown
                  label={currentreport}
                  className="border border-gray-600 rounded-full px-4 py-1 text-center text-xs truncate w-full"
                  containerClassName="w-48"
                >
                  {Object.keys(settings).map(key => (
                    <DropdownItem key={key} onClick={() => dispatch(storeCurrentReport(key))}>
                      {key}
                    </DropdownItem>
                  ))}
                  <DropdownSeparator />
                  <DropdownItem onClick={() => setCreateFormHidden(false)} className="text-gray-600">Créer un nouveau rapport</DropdownItem>
                  <DropdownItem onClick={() => setDeleteFormHidden(false)} className="text-gray-600">Supprimer un rapport</DropdownItem>
                </Dropdown>
              </div>
            </div>
            <div>
              <div>
                <span className="font-semibold">Période :</span>
              </div>
              <div className="flex items-center">
                <DateRangePicker
                  startDate={range.from ? moment(range.from) : null}
                  startDateId="from"
                  endDate={range ? moment(range.to) : null}
                  endDateId="to"
                  onDatesChange={({ startDate, endDate }) => dispatch(storeDateRange({
                    from: startDate ? startDate.format('YYYY-MM-DD') : null,
                    to: endDate ? endDate.format('YYYY-MM-DD') : null,
                  }))}
                  focusedInput={focused}
                  onFocusChange={setFocused}
                  isOutsideRange={() => false}
                />
                <Dropdown
                  label="Selectionner"
                  className="border border-gray-600 rounded-full px-4 py-1 text-center text-xs truncate w-full"
                  containerClassName="ml-4"
                >
                  <DropdownItem onClick={() => handePressPeriod('current_month')}>Ce mois-ci</DropdownItem>
                  <DropdownItem onClick={() => handePressPeriod('last_month')}>Le mois dernier</DropdownItem>
                  <DropdownItem onClick={() => handePressPeriod('last_3_months')}>3 derniers mois</DropdownItem>
                  <DropdownItem onClick={() => handePressPeriod('last_6_months')}>6 derniers mois</DropdownItem>
                  <DropdownItem onClick={() => handePressPeriod('current_year')}>Cette année</DropdownItem>
                  <DropdownItem onClick={() => handePressPeriod('last_year')}>L'année dernière</DropdownItem>
                </Dropdown>
                <button
                  type="button"
                  onClick={handleExport}
                  className="ml-8 border border-gray-600 rounded-full px-4 py-1 text-center text-xs"
                >
                  Exporter les commandes
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="h-px my-8 bg-gray-700 rounded-full" />
        {children}
      </div>
      <CreateSettings hidden={create_form_hidden} onClose={() => setCreateFormHidden(true)} />
      <DeleteSettings hidden={delete_form_hidden} onClose={() => setDeleteFormHidden(true)} />
    </>
  );
};
