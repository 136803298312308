import React, { useState, useEffect, useRef } from 'react';
import LineChart from './chart/line-chart';
import BarChart from './chart/bar-chart';

/* eslint-disable react/prop-types */
export default ({
  label,
  data,
  dataKey,
  tick,
  height = 320,
}) => {
  // Local state
  const [isLineChart, setLineChart] = useState(true);
  const [isCumulative, setCumulative] = useState(!dataKey.endsWith('_rate'));
  const [width, setWidth] = useState(0);
  const node = useRef(null);

  // Init chart width
  useEffect(() => {
    setWidth(node.current.offsetWidth);
  }, [node]);

  // Map values
  let values = data.map(item => ({
    tick: item.timestamp,
    value: item[dataKey],
  }));

  // Cumulative
  if (isCumulative) {
    values = values.reduce((acc, item, i) => acc.concat({
      ...item,
      value: item.value + (acc[i - 1] || { value: 0 }).value,
    }), []);
  }

  // Pick Component according chart type
  const Chart = isLineChart ? LineChart : BarChart;

  return (
    <div key={dataKey} className="px-8 py-4 rounded-lg border border-gray-700">
      <div className="flex justify-between items-center">
        <div className="w-16" />
        <p className="font-medium text-lg">{label}</p>
        <p className="flex items-center justify-end -mr-1">
          <button
            type="button"
            onClick={() => setLineChart(!isLineChart)}
            title="Line / Bar"
            className={isLineChart ? 'p-1 text-blue-500' : 'p-1'}
          >
            ∿
          </button>
          {!dataKey.endsWith('_rate') && (
            <button
              type="button"
              onClick={() => setCumulative(!isCumulative)}
              title="Cumulative"
              className={isCumulative ? 'p-1 ml-1 text-blue-500' : 'p-1 ml-1'}
            >
              Σ
            </button>
          )}
        </p>
      </div>
      <div style={{ minHeight: `${height}px` }} className="mt-4" ref={node}>
        {width && (
          <Chart
            width={width}
            height={height}
            data={values}
            dataKey={dataKey}
            tick={tick}
            grid
          />
        )}
      </div>
    </div>
  );
};
