export default function (state = 'Rapport par défault', { type, payload }) {
  switch (type) {
    // Store
    case 'current-report:store':
      return payload;

    default:
      return state;
  }
}
