export default function (state = {}, { type, payload }) {
  switch (type) {
    // Store
    case 'settings:store':
      return payload;

    // Add
    case 'settings:add':
      return { ...state, ...payload };

    // Delete
    case 'settings:remove':
      return Object.keys(state).reduce((acc, item) => {
        if (item !== payload) {
          return { ...acc, [item]: state[item] };
        }

        return acc;
      }, {});

    default:
      return state;
  }
}
