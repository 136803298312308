export default function (state = { data: [] }, { type, payload }) {
  switch (type) {
    // Store
    case 'facts-data:store':
      return payload;

    default:
      return state;
  }
}
