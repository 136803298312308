export const DIMENSIONS = [
  { key: 'user', label: 'Consultants' },
  { key: 'school', label: 'Écoles' },
  { key: 'brand', label: 'Marques' },
  { key: 'country', label: 'Pays' },
  { key: 'city', label: 'Villes' },
  { key: 'course', label: 'Types de programme' },
  { key: 'accommodation', label: 'Types de logement' },
  { key: 'catering', label: 'Restaurations' },
  { key: 'insurance', label: 'Assurances' },
  { key: 'payment_method', label: 'Modes de paiement' },
  { key: 'instalment', label: 'Mensualisations' },
  { key: 'currency', label: 'Devises' },
  { key: 'type', label: 'Type de commandes' },
  { key: 'status', label: 'Étapes' },
  { key: 'acquisition', label: 'Canal d\'acquisition' },
];

export const FACTS = [
  { key: 'total_amount', label: 'Chiffre d\'Affaires' },
  { key: 'pcf_amount', label: 'CPF' },
  { key: 'paid_amount', label: 'Payés' },
  // { key: 'all_clients', label: 'Total prospects + clients' },
  { key: 'total_prospects', label: 'Nouveaux prospects' },
  { key: 'total_clients', label: 'Nouveaux clients' },
  { key: 'total_clients_rate', label: 'Conversion clients' },
  { key: 'total_estimates', label: 'Nbre de devis' },
  { key: 'total_orders', label: 'Nbre de commandes' },
  { key: 'net_margin_amount', label: 'Marge nette' },
  { key: 'net_margin_rate', label: 'Marge nette %' },
  { key: 'gross_margin_amount', label: 'Marge brute' },
  { key: 'gross_margin_rate', label: 'Marge brute %' },
  { key: 'vat_amount', label: 'T.V.A' },
  { key: 'vat_rate', label: 'T.V.A %' },
  { key: 'cancellation_insurance_amount', label: 'Assurance annulation' },
  { key: 'health_insurance_amount', label: 'Assurance rappatriement' },
  { key: 'total_notes', label: 'Notes internes' },
  { key: 'total_messages', label: 'Nbre de messages' },
  { key: 'total_mails', label: 'Nbre de mails' },
  { key: 'total_sessions', label: 'Session CRM' },
  { key: 'total_clics', label: 'Clics CRM' },
  { key: 'total_pages', label: 'Pages vues CRM' },
  { key: 'total_duration', label: 'Durée CRM' },
];

export const FACT_LABELS = FACTS.reduce((acc, item) => ({
  ...acc,
  [item.key]: item.label,
}), {});

export const FACT_COLORS = {
  total_amount: '#fc9c9e',
  paid_amount: '#f9be9f',
  profit_amount: '#fcd19c',
  total_estimates: '#fbdf9d',
  total_orders: '#bfd9ab',
  net_margin_amount: '#b6e2d5',
  net_margin_rate: '#a6b9c9',
  gross_margin_amount: '#b4b7a4',
  gross_margin_rate: '#e4c6b4',
  vat_amount: '#f1dca7',
  vat_rate: '#deb091',
  cancellation_insurance_amount: '#beab9d',
  health_insurance_amount: '#b2f5ea',
  total_notes: '#a9a9ef',
  total_messages: '#efbdeb',
};

export const COLORS = [
  '#4299e1',
  '#38a169',
  '#38b2ac',
  '#5a67d8',
  '#9f7aea',
  '#ed64a6',
  '#2f855a',
  '#2c7a7b',
  '#4c51bf',
  '#6b46c1',
  '#f687b3',
  '#d69e2e',
  '#dd6b20',
  '#e53e3e',
  '#718096',
  '#9ae6b4',
  '#b2f5ea',
  '#90cdf4',
  '#a3bffa',
  '#b794f4',
];

export const API_URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:81/reporting/api'
  : 'https://crm.voyage-langue.com/reporting/api';
