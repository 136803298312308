import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { hideNotification } from 'actions/notification';

export default () => {
  const dispatch = useDispatch();
  const notification = useSelector(state => state.notification);

  // Hide on click
  const handleClick = useCallback(() => dispatch(hideNotification()), [dispatch]);

  // Hide after 3 sec
  useEffect(() => {
    let timer;

    if (notification) {
      setTimeout(() => dispatch(hideNotification()), 3000);
    }

    return () => (timer && clearTimeout(timer));
  }, [dispatch, notification]);

  // No notification
  if (!notification) {
    return null;
  }

  return (
    <div className="notification-effect fixed flex justify-center top-0 w-full mt-32 cursor-pointer" onClick={handleClick}>
      <div
        className={classNames(
          'px-8 py-4 text-gray-200 rounded-full shadow',
          { 'bg-blue-700': notification.type !== 'error' && notification.type !== 'success' },
          { 'bg-red-700': notification.type === 'error' },
          { 'bg-green-700': notification.type === 'success' },
        )}
      >
        {notification.message}
      </div>
    </div>
  );
};
