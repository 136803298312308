import { put, select, takeEvery } from 'redux-saga/effects';
import { getColor } from 'helpers';
import { storeDimensionsData } from 'actions/dimensions-data';
import fetchData from './api';

function* doFetchDimensions() {
  // Get redux
  const daterange = yield select(state => state.daterange);
  const dimension = yield select(state => state.currentdimension);
  const order = yield select(state => state.currentorder);

  if (daterange.from && daterange.to) {
    const data = yield fetchData(`dimensions/${dimension.key}?from=${daterange.from}&to=${daterange.to}`);

    if (data) {
      yield put(storeDimensionsData({
        payload: data.payload,
        dimensions: (
        // Sort data
          order
            ? data.dimensions.sort((a, b) => data.payload[b.id][order] - data.payload[a.id][order])
            : data.dimensions.sort((a, b) => a.name.localeCompare(b.name))

        // Add color
        ).map((item, i) => ({ ...item, color: item.color || getColor(i) })),
      }));
    }
  }
}

/* eslint-disable  import/prefer-default-export */
export function* watchFetchDimensions() {
  yield takeEvery(['current-dimension:store', 'date-range:store', 'fetch-inital-data'], doFetchDimensions);
}
/* eslint-enable  import/prefer-default-export */
