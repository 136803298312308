import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'components/dropdown';
import DropdownItem from 'components/dropdown/item';
import useCurrentIndicators from 'hooks/current-indicators';
import { storeCurrentOrder } from 'actions/current-order';
import { FACT_LABELS } from 'helpers/constants';

export default () => {
  // Redux
  const dispatch = useDispatch();
  const order = useSelector(state => state.currentorder);
  const indicators = useCurrentIndicators();

  return (
    <Dropdown
      label={FACT_LABELS[order] || 'Trier par'}
      className="border border-gray-600 rounded-full px-4 py-1 text-center text-xs truncate w-full"
      containerClassName="w-full"
    >
      {indicators.map(item => (
        <DropdownItem key={item} onClick={() => dispatch(storeCurrentOrder(item))}>
          {FACT_LABELS[item]}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};
