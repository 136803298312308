import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reducers from 'reducers';
import { formatDate, firstOfMonth } from 'helpers';
import rootSaga from './saga';

// Init middlewares
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

// Debug ?
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger({ collapsed: true }));
}

// Create store
const store = createStore(reducers, {
  credentials: localStorage.getItem('credentials'),
  daterange: {
    from: firstOfMonth(new Date()),
    to: formatDate(new Date()),
  },
}, applyMiddleware(...middlewares));

// Saga
sagaMiddleware.run(rootSaga);

// Return store
export default store;
