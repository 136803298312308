import React from 'react';
import { formatValue } from 'helpers';
import { FACT_COLORS } from 'helpers/constants';

/* eslint-disable max-len, react/prop-types */
export default ({
  dimension,
  data,
  dataKeys,
  max,
  color,
}) => (
  <div className="card text-center p-4 rounded-lg flex flex-col justify-around relative border-2 border-transparent">
    {dataKeys.map(dataKey => (
      <div className="flex items-center" key={dataKey}>
        <div
          className="flex-grow rounded my-4 h-2"
          style={{
            backgroundColor: color, // FACT_COLORS[dataKey],
            maxWidth: `${(Math.max(0, data[dimension.id][dataKey] || 0) * 90) / (!dataKey.endsWith('_rate') ? max : 100)}%`,
          }}
        />
        <div className="text-xs ml-1 w-1/5 text-left truncate" title={formatValue(data[dimension.id][dataKey] || 0, dataKey)}>
          {formatValue(data[dimension.id][dataKey] || 0, dataKey)}
          {/* <span className="text-yellow-500"> ★</span> */}
        </div>
      </div>
    ))}
  </div>
);
