// Store settings
export function storeSettings(payload) {
  return { type: 'settings:store', payload };
}

// Add settings
export function addSettings(payload) {
  return { type: 'settings:add', payload };
}

// Remove settings
export function removeSettings(payload) {
  return { type: 'settings:remove', payload };
}

// Fetch settings
export function fetchSettings(payload) {
  return { type: 'settings:fetch', payload };
}

// Create settings remotely
export function createSettings(payload) {
  return { type: 'settings:create', payload };
}

// Delete settings remotely
export function deleteSettings(payload) {
  return { type: 'settings:delete', payload };
}
