import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Router } from '@reach/router';

// Import screens
import Notification from 'components/notification';
import LoginModal from './misc/login';
import Layout from './layout';
import Home from './home';
import Profile from './profile';
import Compare from './compare';
import NotFound from './404';

export default () => {
  // Redux
  const dispatch = useDispatch();

  // Fetch inital data
  useEffect(() => {
    dispatch({ type: 'fetch-inital-data' });
  }, [dispatch]);

  return (
    <>
      <Router primary={false}>
        <Layout path="/">
          <Home path="/" />
          <Profile path="profile/:id" />
          <Compare path="compare/:ids" />
          <NotFound default />
        </Layout>
      </Router>
      <LoginModal />
      <Notification />
    </>
  );
};
