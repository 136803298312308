import { put, select, takeEvery } from 'redux-saga/effects';
import { storeFactsData } from 'actions/facts-data';
import fetchData from './api';

function* doFetchFacts() {
  // Get redux
  const daterange = yield select(state => state.daterange);

  if (daterange.from && daterange.to) {
    const data = yield fetchData(`facts?from=${daterange.from}&to=${daterange.to}`);

    if (data) {
      yield put(storeFactsData(data));
    }
  }
}

/* eslint-disable  import/prefer-default-export */
export function* watchFetchFacts() {
  yield takeEvery(['date-range:store', 'fetch-inital-data'], doFetchFacts);
}
/* eslint-enable  import/prefer-default-export */
