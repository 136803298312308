import React, { useState, useEffect, useCallback } from 'react';
import DropdownList from './list';

/* eslint-disable react/prop-types */
export default function Dropdown({
  label,
  children,
  className = '',
  containerClassName = '',
}) {
  // hidden state
  const [hidden, setHidden] = useState(true);

  // Hide dropdow
  const handleHide = useCallback(() => {
    setHidden(true);
    document.removeEventListener('click', handleHide);
  }, []);

  // Show dropdown
  const handleShow = useCallback(() => {
    setHidden(false);
    document.addEventListener('click', handleHide);
  }, [handleHide]);

  // Remove listener on umount
  useEffect(() => () => document.removeEventListener('click', handleHide), [handleHide]);

  return (
    <div className={`dropdown ${containerClassName}`}>
      <button type="button" onClick={handleShow} className={className}>
        {label}
      </button>
      <DropdownList hidden={hidden}>{children}</DropdownList>
    </div>
  );
}
