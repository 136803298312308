import React from 'react';
import classNames from 'classnames';
import { formatValue } from 'helpers';

/* eslint-disable max-len, react/prop-types, jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
export default ({
  data,
  dataKey,
  active = false,
  onPress,
  average,
}) => {
  const max = Object.keys(data.payload).reduce((acc, key) => Math.max(acc, data.payload[key][dataKey]), 0);
  const avg = (average * 90) / max;

  return (
    <div
      className={classNames(
        'card text-center p-4 rounded-lg flex flex-col justify-around relative cursor-pointer border-2 border-transparent',
        { 'border-blue-600': active },
      )}
      onClick={onPress}
    >
      {data.dimensions.map(item => (
        <div className="flex items-center" key={item.id}>
          <div
            className="flex-grow rounded my-4 h-2"
            style={{
              backgroundColor: item.color,
              width: `${(Math.max(0, data.payload[item.id][dataKey]) * 90) / max}%`,
              minWidth: `${(Math.max(0, data.payload[item.id][dataKey]) * 90) / max}%`,
              maxWidth: `${(Math.max(0, data.payload[item.id][dataKey]) * 90) / max}%`,
            }}
          />
          <div className="w-12 text-left text-xs pl-1">{formatValue(data.payload[item.id][dataKey], dataKey)}</div>
        </div>
      ))}
      {!dataKey.endsWith('_rate') && (
        <div className="absolute w-full h-full px-4" style={{ top: '20px', left: 0 }}>
          <div
            className="w-px bg-gray-100 h-full opacity-25"
            style={{ marginLeft: `${avg}%` }}
          />
          <div
            className="text-xs text-left leading-none mt-2 relative"
            style={{ marginLeft: `${avg}%`, left: '-5px' }}
          >
            {formatValue(Math.round(average * 100) / 100, dataKey)}
          </div>
        </div>
      )}
    </div>
  );
};
