import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link } from '@reach/router';
import { FunnelChart, Tooltip, Funnel } from 'recharts';
import { displayNotification } from 'actions/notification';
import useDimensionPayload from 'hooks/dimension-payload';
import { getPosition } from 'helpers';
import { FACT_LABELS } from 'helpers/constants';
import useCurrentIndicators from 'hooks/current-indicators';
import useApi from 'hooks/api';
import PanelChart from 'components/panel-chart';
import Fact from './components/fact';
import EditProfile from './components/edit-profile';

/* eslint-disable react/prop-types, camelcase */
export default ({ id }) => {
  // Local state
  const [funnel, setFunnel] = useState([]);
  const [hidden_edit_profile, hideEditProfile] = useState(true);

  // Redux
  const dispatch = useDispatch();
  const daterange = useSelector(state => state.daterange);
  const count = useSelector(state => (state.dimensionsdata.dimensions || []).length);
  const facts = useSelector(state => state.factsdata, shallowEqual);
  const all = useSelector(state => state.dimensionsdata.payload, shallowEqual);
  const currentdimension = useSelector(state => state.currentdimension);
  const { name, data } = useDimensionPayload(id);
  const indicators = useCurrentIndicators();
  const fetchData = useApi();

  // Load funnel data
  useEffect(() => {
    fetchData(`funnel/${currentdimension.key}/${id}?from=${daterange.from}&to=${daterange.to}`)
      .then(payload => setFunnel([
        {
          value: payload.total || 0,
          fill: '#5991D9A0',
        },
        {
          value: payload.has_orders || 0,
          fill: '#74AEB9A0',
        },
        {
          value: payload.has_payment || 0,
          fill: '#8FCA9AA0',
        },
        {
          value: payload.has_fullypayment || 0,
          fill: '#AAE77AA0',
        },
      ]))
      .catch(({ message }) => dispatch(displayNotification({ message, type: 'error' })));
  }, [daterange]);

  if (!name) {
    return (
      <div className="fade-in">
        <div className="mb-16">
          <Link to="/">
            <img src="/left-arrow.png" alt="left arrow" className="h-16 w-16" />
          </Link>
        </div>
        <div className="flex items-center justify-center text-4xl mt-32">Dimension non présente sur la période ...</div>
      </div>
    );
  }

  return (
    <div className="fade-in">
      <EditProfile hidden={hidden_edit_profile} userId={id} onClose={() => hideEditProfile(true)} />
      <div className="flex justify-between items-center mb-16">
        <div className="flex items-center mr-16">
          <Link to="/">
            <img src="/left-arrow.png" alt="left arrow" className="h-16 w-16" />
          </Link>
          <div className="flex items-center ml-8">
            <div className="ml-4">
              <div className="text-4xl">{name}</div>
            </div>
            {currentdimension.key === 'user' && (
              <button
                type="button"
                onClick={() => hideEditProfile(false)}
                className="ml-8 border border-gray-600 rounded-full px-4 py-1 text-center text-xs"
              >
                Modifier
              </button>
            )}
          </div>
        </div>
        <div className="flex">
          {funnel.length > 0 && (
            <div className="flex flex-col mr-4 items-end">
              <p className="leading-7 flex items-center">
                Prospects + Clients total:
                <span className="w-16 text-right">{funnel[0].value || 0}</span>
                <small className="font-normal text-xs ml-2">| 100 %&nbsp;</small>
              </p>
              <p className="leading-7 flex items-center">
                ... avec un devis ou une commande en cours:
                <span className="w-16 text-right">{funnel[1].value || 0}</span>
                <small className="font-normal text-xs ml-2">{`| ${((funnel[1].value / funnel[0].value) * 100).toFixed(1)} %`}</small>
              </p>
              <p className="leading-7 flex items-center">
                ... avec un paiement:
                <span className="w-16 text-right">{funnel[2].value || 0}</span>
                <small className="font-normal text-xs ml-2">{`| ${((funnel[2].value / funnel[0].value) * 100).toFixed(1)} %`}</small>
              </p>
              <p className="leading-7 flex items-center">
                ... avec un paiement complet:
                <span className="w-16 text-right">{funnel[3].value || 0}</span>
                <small className="font-normal text-xs ml-2">{`| ${((funnel[3].value / funnel[0].value) * 100).toFixed(1)} %`}</small>
              </p>
            </div>
          )}
          <FunnelChart width={256} height={128}>
            <Tooltip />
            <Funnel
              dataKey="value"
              data={funnel}
              isAnimationActive
              stroke="#2a323c"
              strokeWidth={2}
            />
          </FunnelChart>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 mb-8">
        {indicators.map(dataKey => (
          <Fact
            key={dataKey}
            dataKey={dataKey}
            value={data[dataKey]}
            total={facts[dataKey]}
            position={getPosition(all, id, dataKey)}
            count={count}
          />
        ))}
      </div>
      <div className="w-64 h-2 mx-auto my-12 bg-gray-700 rounded-full" />
      <div className="grid grid-cols-2 gap-12">
        {indicators.map(dataKey => (
          <PanelChart
            key={dataKey}
            label={FACT_LABELS[dataKey]}
            data={data.data}
            dataKey={dataKey}
          />
        ))}
      </div>
    </div>
  );
};
