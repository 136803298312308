import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import useCurrentIndicators from 'hooks/current-indicators';
import useCompareDimensions from 'hooks/compare-dimensions';
import MultiCharts from 'components/multi-charts';
import { FACT_LABELS } from 'helpers/constants';

// Local components
import OrderDropdown from './components/order-dropdown';
import FactLabels from './components/fact-labels';
import ProgressBar from './components/progress-bar';

/* eslint-disable react/prop-types */
export default ({ ids }) => {
  // Local state
  const [checked, setChecked] = useState('');
  const [charts_data, setChartsData] = useState([]);

  // Redux
  const indicators = useCurrentIndicators();

  // Load dimensions according ids passed to url
  const { dimensions, data } = useCompareDimensions(ids);

  // Compute charts data
  useEffect(() => {
    // Create empty item
    const empty = dimensions.reduce((acc, item) => ({ ...acc, [item.id]: 0 }), {});

    // Group each dimensions value by timestamp
    const mapped = Object.keys(data).reduce((acc, key) => (
      // Parse each dimension data
      (data[key]?.data || []).reduce((items, item) => ({
        ...items,
        [item.timestamp]: {
          ...empty,
          ...items[item.timestamp],
          [key]: item[checked],
        },
      }), acc)
    ), {});

    // Map to array
    setChartsData(Object.keys(mapped).reduce((acc, key) => (
      acc.concat({
        tick: parseInt(key),
        value: Math.max(...Object.keys(mapped[key]).map(k => mapped[key][k])),
        ...mapped[key],
      })
    ), []));
  }, [checked]);

  // Load max values
  const maxValues = indicators.reduce((acc, indicator) => ({
    ...acc,
    [indicator]: Object.keys(data).reduce((max, key) => Math.max(max, (data[key] || {})[indicator] || 0), 0),
  }), {});

  const max = Object.keys(maxValues).reduce((acc, item) => Math.max(acc, maxValues[item]), 0);

  return (
    <div className="fade-in">
      <div className="grid grid-cols-12 gap-4">
        <Link to={`/?ids=${ids}`} className="col-span-1">
          <img src="/left-arrow.png" alt="left arrow" className="h-16 w-16" />
        </Link>
        <div className="col-span-11">
          {checked.length > 0 && (
            <MultiCharts
              label={FACT_LABELS[checked]}
              data={charts_data}
              dataKey={checked}
              dimensions={dimensions.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})}
              height={280}
              tick={data[Object.keys(data)[0]]?.tick}
            />
          )}
        </div>
      </div>
      <div className="w-64 h-2 mx-auto mt-4 mb-12 bg-gray-700 rounded-full" />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-1 self-end text-right">
          <OrderDropdown />
        </div>
        <div className="col-span-11">
          <div className="grid grid-cols-5 gap-4">
            {dimensions.map(item => (
              <div key={item.id.toString()} className="card p-4 rounded-lg font-medium text-lg">
                <div className="flex justify-between items-center">
                  <p className="font-medium text-lg">{item.name}</p>
                  <p className="w-6 h-6" style={{ backgroundColor: item.color }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 my-4">
        <div className="col-span-1 flex flex-col justify-around text-right py-4">
          <FactLabels
            data={indicators}
            checked={checked}
            onCheck={setChecked}
          />
        </div>
        <div className="col-span-11">
          <div className="grid grid-cols-5 gap-4">
            {dimensions.map(item => (
              <ProgressBar
                key={item.id.toString()}
                dimension={item}
                data={data}
                dataKeys={indicators}
                max={max}
                color={item.color}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
