import { COLORS } from './constants';

export function formatAmount(value) {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);
}

export function formatValue(value, type = '') {
  switch (type.split('_').pop()) {
    case 'amount':
      return formatAmount(value);

    case 'rate':
      return `${value.toFixed(1)}%`;

    case 'duration':
      if (value && value !== Infinity) {
        return `${Math.floor(value / 3600).toString().padStart(2, '0')}h${Math.floor((value % 3600) / 60).toString().padStart(2, '0')}`;
      }

      return '-';

    default:
      return value;
  }
}

export function formatTick(time, type) {
  const d = new Date(time * 1000);

  switch (type) {
    case 'week':
    {
      const onejan = new Date(d.getFullYear(), 0, 1);
      const week = Math.ceil((((d - onejan) / 86400000) + onejan.getDay() + 1) / 7);
      return `Sem. #${week}`;
    }

    case 'month':
    {
      const months = ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Dec'];
      return `${months[d.getMonth()]} ${d.getFullYear()}`;
    }

    default:
      return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
  }
}

export function getColor(i) {
  return COLORS[i % COLORS.length];
}

export function formatDate(date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

export function getDateFromIdday(id) {
  return new Date(
    parseInt(id.toString().substr(0, 4), 10),
    parseInt(id.toString().substr(4, 2), 10) - 1,
    parseInt(id.toString().substr(-2), 10),
  );
}

export function getDateFromTimestamp(time) {
  const d = new Date(time * 1000);

  return d.getDate().toString().padStart(2, '0')
    + '/'
    + (d.getMonth() + 1).toString().padStart(2, '0')
    + '/' + d.getFullYear();
}

export function getShortMonth(time) {
  return [
    'Jan',
    'Fev',
    'Mar',
    'Avr',
    'Mai',
    'Juin',
    'Juil',
    'Aout',
    'Sept',
    'Oct',
    'Nov',
    'Dec'][new Date(time).getMonth()];
}

export function firstOfMonth(date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-01`;
}

// Get position of dimension {id} from all dimension by dataKey
export function getPosition(data, id, dataKey) {
  if (!data[id] || !data[id][dataKey]) {
    return 0;
  }

  const ref = data[id][dataKey];

  return Object.keys(data).reduce((acc, key) => {
    if (data[key][dataKey] > ref) {
      return acc + 1;
    }

    return acc;
  }, 1);
}
