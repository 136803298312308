import React from 'react';
import { Link } from '@reach/router';

/* eslint-disable react/prop-types */
export default ({
  data,
  checked,
  onCheck,
  onUncheck,
}) => (
  <>
    {data.map(item => (
      <div key={item.id.toString()} className="flex items-center justify-end">
        <input
          type="checkbox"
          className="mr-2"
          checked={checked.includes(item.id)}
          onChange={event => {
            if (event.target.checked) {
              onCheck(item.id);
            } else {
              onUncheck(item.id);
            }
          }}
          disabled={!checked.includes(item.id) && checked.length >= 5}
        />
        <Link to={`/profile/${item.id}`}>
          <span className="text-gray-400 leading-4 truncate" title={item.name}>
            {item.name}
          </span>
        </Link>
      </div>
    ))}
  </>
);
