import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeCredentials } from 'actions/credentials';

/* eslint-disable jsx-a11y/label-has-associated-control */
export default () => {
  // Local state
  const [formData, setFormData] = useState({ username: '', password: '' });

  // Redux
  const dispatch = useDispatch();
  const credentials = useSelector(state => state.credentials);

  // Handle on submit
  const handleSubmit = event => {
    event.preventDefault();
    dispatch(storeCredentials(formData));
    dispatch({ type: 'fetch-inital-data' });
  };

  if (credentials) {
    return null;
  }

  return (
    <form
      className="w-full fixed w-full h-full inset-0 flex justify-center items-center"
      style={{ backgroundColor: '#00000020' }}
      onSubmit={handleSubmit}
    >
      <div
        className="p-8 rounded-lg"
        style={{
          backgroundColor: '#323c48',
          boxShadow: '0px 0px 27px rgba(0, 0, 0, 0.5)',
        }}
      >
        <div className="text-gray-200 text-xl mb-8 pb-2 border-b border-gray-200">
          Authentication
        </div>
        <div className="md:flex md:items-center mb-6 px-8">
          <div className="md:w-1/3">
            <label className="block text-gray-200 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Username
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="text"
              placeholder="Fill your username"
              value={formData.username}
              onChange={event => setFormData({ ...formData, username: event.target.value })}
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6 px-8">
          <div className="md:w-1/3">
            <label className="block text-gray-200 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Password
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="password"
              placeholder="Fill your password"
              value={formData.password}
              onChange={event => setFormData({ ...formData, password: event.target.value })}
            />
          </div>
        </div>
        <div className="md:flex md:items-center px-8">
          <div className="md:w-1/3" />
          <div className="md:w-2/3">
            <button
              className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
