import React from 'react';
import {
  PieChart, Pie, Cell,
} from 'recharts';

const COLORS = ['#98a4b9', '#323c48', '#38b2ac', '#FF8042'];

// eslint-disable-next-line react/prop-types
export default ({ rate, height }) => {
  const data = [{ value: rate }, { value: 1 - rate }];

  return (
    <PieChart width={height} height={height}>
      <Pie
        data={data}
        cx={height / 2}
        cy={height / 2}
        outerRadius={height / 3}
        dataKey="value"
        stroke={COLORS[0]}
        strokeWidth={1}
      >
        {data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
      </Pie>
    </PieChart>
  );
};
