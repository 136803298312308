export default function (state = null, { type, payload }) {
  switch (type) {
    // Store
    case 'current-order:store':
      return payload;

    default:
      return state;
  }
}
