import { useDispatch, useSelector } from 'react-redux';
import { setIsFetching } from 'actions/is-fetching';
import { storeCredentials } from 'actions/credentials';
import { API_URL } from 'helpers/constants';

export default () => {
  // Redux
  const credentials = useSelector(state => state.credentials);
  const dispatch = useDispatch();

  return (url, params = { method: 'GET' }) => {
    dispatch(setIsFetching(true));

    return fetch(`${API_URL}/${url}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(credentials)}`,
      },
      method: params.method,
      body: params.data ? JSON.stringify(params.data) : null,
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }

        if (response.status === 401) {
          dispatch(storeCredentials(null));
          throw new Error('Unauthorized to use API');

        // Display error code
        } else {
          throw new Error(`Unexpected code from API: ${response.status}`);
        }
      })
      .finally(() => dispatch(setIsFetching(false)));
  };
};
