import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// Init date range packages
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// Load App
import App from 'screens/app';
import 'styles/main.css';
import store from './store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
