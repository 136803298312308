import { put, takeEvery } from 'redux-saga/effects';
import { storeSettings, addSettings, deleteSettings } from 'actions/settings';
import fetchData from './api';

//---
// Fetch all settings
//---
function* doFetchSettings() {
  const response = yield fetchData('settings');

  if (response) {
    yield put(storeSettings(response));
  }
}

export function* watchFetchSettings() {
  yield takeEvery(['settings:fetch', 'fetch-inital-data'], doFetchSettings);
}

//---
// Create settings remotely
//---
function* doCreateSettings({ payload }) {
  const response = yield fetchData('settings', { method: 'POST', params: payload });

  if (response) {
    yield put(addSettings(response));
  }
}

export function* watchCreateSettings() {
  yield takeEvery('settings:create', doCreateSettings);
}

//---
// Delete settings remotely
//---
function* doDeleteSettings({ payload }) {
  const response = yield fetchData(`settings/${payload}`, { method: 'DELETE' });

  if (response) {
    yield put(deleteSettings(payload));
  }
}

export function* watchDeleteSettings() {
  yield takeEvery('settings:delete', doDeleteSettings);
}
