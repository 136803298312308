import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { addSettings } from 'actions/settings';
import { displayNotification } from 'actions/notification';
import { FACTS } from 'helpers/constants';
import useApi from 'hooks/api';

/* eslint-disable react/prop-types, jsx-a11y/label-has-associated-control */
export default ({ hidden, onClose }) => {
  // Local state
  const [formData, setFormData] = useState({ name: '', value: [] });

  // Redux
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings);
  const fetchData = useApi();

  // Handle on submit
  const handleSubmit = useCallback(event => {
    event.preventDefault();

    // Check params
    if (!formData.name.length || !formData.value.length) {
      dispatch(displayNotification({ message: 'Nom et Liste des valeurs obligatoires', type: 'error' }));

    // ... Already exists ?
    } else if (Object.keys(settings).includes(formData.name)) {
      dispatch(displayNotification({ message: `Un rapport "${formData.name}" existe déjà`, type: 'error' }));

    // ... Post request
    } else {
      fetchData('settings', {
        method: 'POST',
        data: {
          name: formData.name,
          value: formData.value.join(','),
        },
      })
        .then(response => {
          onClose();
          setFormData({ name: '', value: [] });
          dispatch(addSettings(response));
          dispatch(displayNotification({ message: 'Succès', type: 'success' }));
        })
        .catch(({ message = 'Error while requesting API' }) => dispatch(displayNotification({
          message,
          type: 'error',
        })));
    }
  }, [dispatch, formData]);

  if (hidden) {
    return null;
  }

  return (
    <form
      className="fade-in w-full fixed w-full h-full inset-0 flex justify-center items-center"
      style={{ backgroundColor: '#00000020' }}
      onSubmit={handleSubmit}
    >
      <div
        className="p-8 rounded-lg w-1/2"
        style={{
          backgroundColor: '#323c48',
          boxShadow: '0px 0px 27px rgba(0, 0, 0, 0.5)',
        }}
      >
        <div className="text-gray-200 text-xl mb-8 pb-2 border-b border-gray-200">
          Nouveau rapport
        </div>
        <div className="flex items-center mb-6 px-8">
          <div className="w-1/5">
            <label className="block text-gray-200 font-bold text-right mb-1 mb-0 pr-4">
              Nom du rapport
            </label>
          </div>
          <div className="w-4/5">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="text"
              value={formData.name}
              onChange={event => setFormData({ ...formData, name: event.target.value })}
            />
          </div>
        </div>
        <div className="flex items-center mb-6 px-8">
          <div className="w-1/5">
            <label className="block text-gray-200 font-bold text-right mb-1 mb-0 pr-4">
              Liste des valeurs
            </label>
          </div>
          <div className="w-4/5">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="text"
              value={formData.value.join(',')}
              onChange={event => setFormData({ ...formData, value: event.target.value.split(',').filter(elem => elem) })}
            />
          </div>
        </div>
        <div className="flex items-center mb-6 px-8">
          <div className="w-1/5" />
          <div className="w-4/5">
            <div className="grid grid-cols-4 gap-4">
              {FACTS.map(item => (
                <div key={item.key}>
                  <button
                    type="button"
                    onClick={() => setFormData({
                      ...formData,
                      value: [...new Set(formData.value.includes(item.key)
                        ? formData.value.filter(elem => elem && elem !== item.key)
                        : formData.value.filter(elem => elem).concat(item.key))].slice(0, 5),
                    })}
                    className={classNames('truncate w-full border hover:border-gray-200 hover:text-gray-200 rounded-full px-4 py-1 text-center text-xs', {
                      'border-gray-600': !formData.value.includes(item.key),
                      'border-gray-200 text-gray-200': formData.value.includes(item.key),
                    })}
                  >
                    {item.label}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-center px-8">
          <div className="w-1/5" />
          <div className="w-4/5">
            <button
              className="shadow bg-gray-600 hover:bg-gray-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => {
                setFormData({ name: '', value: [] });
                onClose();
              }}
            >
              Annuler
            </button>
            &nbsp;&nbsp;
            <button
              className="shadow bg-blue-700 hover:bg-blue-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Soumettre
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
