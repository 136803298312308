import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatValue, formatTick } from 'helpers';
import { FACT_LABELS } from 'helpers/constants';

/* eslint-disable react/prop-types */
export default ({
  data,
  dataKey,
  tick,
  width,
  height,
  grid = false,
}) => (
  <LineChart data={data} width={width} height={height}>
    {grid && <CartesianGrid strokeDasharray="1 5" stroke="#98a4b9A0" />}
    <YAxis
      hide={!grid}
      dataKey="value"
      type="number"
      domain={['dataMin', 'dataMax']}
      scale="linear"
      tickFormatter={value => formatValue(value, dataKey)}
    />
    <XAxis
      hide={!grid}
      dataKey="tick"
      type="number"
      domain={['dataMin', 'dataMax']}
      scale="time"
      tickFormatter={value => formatTick(value, tick)}
      tickSize={4}
    />
    <Line
      type="monotone"
      dataKey="value"
      fill={grid ? '#38b2ac' : '#98a4b9'}
      stroke={grid ? '#38b2ac' : '#98a4b9'}
      strokeWidth={grid ? 2 : 1}
      dot={grid ? { fill: '#38b2ac', strokeWidth: 0, r: 3 } : false}
      activeDot={grid ? { fill: '#38b2ac', strokeWidth: 0, r: 7 } : { fill: '#98a4b9', strokeWidth: 0, r: 5 }}
    />
    <Tooltip
      labelFormatter={value => formatTick(value, tick)}
      formatter={value => [formatValue(value, dataKey), FACT_LABELS[dataKey]]}
    />
  </LineChart>
);
