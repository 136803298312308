import React from 'react';
import { FACT_LABELS } from 'helpers/constants';

/* eslint-disable react/prop-types */
export default ({ data, checked, onCheck }) => (
  <>
    {data.map(item => (
      <label key={FACT_LABELS[item]} className="flex items-center justify-end cursor-pointer">
        <input
          type="radio"
          name="radiobutton"
          value={item}
          className="mr-2"
          checked={checked === item}
          onChange={event => onCheck(event.target.value)}
        />
        <span className="text-gray-400 leading-4 truncate" title={FACT_LABELS[item]}>
          {FACT_LABELS[item]}
        </span>
      </label>
    ))}
  </>
);
