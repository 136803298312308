import { combineReducers } from 'redux';

import isfetching from './is-fetching';
import notification from './notification';
import credentials from './credentials';
import settings from './settings';
import daterange from './date-range';
import currentdimension from './current-dimension';
import currentreport from './current-report';
import currentorder from './current-order';
import dimensionsdata from './dimensions-data';
import factsdata from './facts-data';

export default combineReducers({
  isfetching,
  notification,
  credentials,
  settings,
  daterange,
  currentdimension,
  currentreport,
  currentorder,
  dimensionsdata,
  factsdata,
});
