export default function (state = 0, { type, payload }) {
  switch (type) {
    // Store
    case 'isfetching:store':
      return payload ? state + 1 : state - 1;

    default:
      return state;
  }
}
