import React from 'react';
import Dropdown from 'components/dropdown';
import DropdownItem from 'components/dropdown/item';
import { DIMENSIONS } from 'helpers/constants';

// eslint-disable-next-line react/prop-types
export default ({ label, onPressItem }) => (
  <Dropdown
    label={label}
    className="border border-gray-600 rounded-full px-4 py-1 text-center text-xs truncate w-full"
    containerClassName="w-full"
  >
    {DIMENSIONS.map(item => (
      <DropdownItem key={item.key} onClick={() => onPressItem(item)}>
        {item.label}
      </DropdownItem>
    ))}
  </Dropdown>
);
