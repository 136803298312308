export default function (state = {}, { type, payload }) {
  switch (type) {
    // Store
    case 'date-range:store':
      return { ...state, ...payload };

    default:
      return state;
  }
}
