import React from 'react';
import { useSelector } from 'react-redux';

export default () => {
  const isfetching = useSelector(state => state.isfetching);

  if (!isfetching) {
    return null;
  }

  return <div className="fade-in fetching-indicator" />;
};
