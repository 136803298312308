import React from 'react';

/* eslint-disable react/prop-types */
export default function DropdownList({ hidden, ...rest }) {
  // Hidden ?
  if (hidden) {
    return null;
  }

  return (
    <div className="dropdown-content">
      <ul role="menu" className="dropdown-list" {...rest} />
    </div>
  );
}
